import Vue from "vue";
import Vuex from "vuex";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { API_BASE_URL } from "./config";
import i18n from "./i18n";
import VueNoty from "vuejs-noty";
import Helper from "./helpers.js";
import GlobalComputed from "./GlobalComputed.js";
import currencySymbol from "./currencySymbol.js";

import "./plugins/vuetify-mask.js";
Vue.use(VueNoty);
Vue.mixin(Helper);
Vue.mixin(GlobalComputed);
Vue.mixin(currencySymbol);

// Vue-Cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.config.productionTip = false;
Vue.config.baseUrl = API_BASE_URL;

export var app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  Vuex,
  data() {
    return {};
  },
  render: (h) => h(App),
}).$mount("#app");
