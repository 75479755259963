<template>
  <v-card>
    <confirmation-model
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-navigation-drawer
      style="z-index:9"
      v-model="$store.state.showNavDrawer"
      app
    >
      <v-list-item>
        <v-list-item-content class="admin-head-top">
          <div>
            <v-list-item-title class="title">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <a
                    class="d-flex align-center profile-dropdown active"
                    style="color:black !important"
                    v-on="on"
                  >
                    {{ $store.state.loginUser }}
                    <v-icon style="color:black !important">
                      mdi-chevron-down
                    </v-icon>
                  </a>
                </template>
                <v-card>
                  <v-list dense>
                    <div v-for="(item, index) in profile_list" :key="index">
                      <template>
                        <v-list-item
                          class="cursor-pointer"
                          :class="{
                            activeLanguage: item.active,
                          }"
                        >
                          <v-hover>
                            <v-list-item-title
                              @click="onListClick(item.action, item.data)"
                            >
                              <v-icon>
                                {{ item.icon }}
                              </v-icon>
                              {{ $t(item.title) }}
                            </v-list-item-title>
                          </v-hover>
                        </v-list-item>
                        <v-divider v-if="item.divider" :key="index"></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-menu>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t("roles.sh_" + getUserRole()) }}@{{ getCompanyName() }}
            </v-list-item-subtitle>
          </div>
          <!-- <v-btn class="mx-2" fab dark color="primary" small @click="logout">
            <v-icon dark> mdi-power-standby </v-icon>
          </v-btn> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Navigation Sidebar -->
      <v-treeview
        v-model="tree"
        :open="active_tree_opened_items"
        :items="nav_items"
        :active.sync="active_tree_items"
        item-key="name"
        activatable
        @update:active="selectActiveKey($event)"
        dense
        open-on-click
        open-all
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.to" @click="$router.push(item.to).catch(() => {})">
            {{ item.icon }}
          </v-icon>
          <v-icon v-else>
            {{ item.icon }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <span v-if="item.to" @click="$router.push(item.to).catch(() => {})">
            {{ $t("" + item.name) }}
          </span>
          <span v-else>
            {{ $t("" + item.name) }}
          </span>
        </template>
      </v-treeview>
    </v-navigation-drawer>
  </v-card>
</template>
<style scoped>
[b1],
.b1 {
  border: 1px dashed red;
}

.list__tile {
  height: auto;
  min-height: 48px;
}

.list__tile__title {
  white-space: normal !important;
  text-overflow: wrap !important;
  word-wrap: break-word;
}
</style>
<script>
import { API_BASE_URL } from "@/config";
import axios from "axios";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  components: {
    "confirmation-model": ConfirmationModel,
  },
  name: "Navigation",
  data: () => ({
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    tree: [],
    active_tree_items: [],
    active_tree_opened_items: ["facturation_nav"],
    nav_items: [
      {
        name: "company",
        icon: "mdi-factory",
        tag: "home_sidebar",
        active: false,
        children: [
          {
            name: "dashboard",
            icon: "mdi-view-dashboard",
            tag: "dashboard",
            to: "/",
            active: true,
          },
          {
            name: "clients_nav",
            icon: "mdi-account",
            tag: "clients",
            to: "/clients",
            active: false,
          },
          {
            name: "articles_nav",
            icon: "mdi-television-guide",
            tag: "articles",
            to: "/articles",
            active: false,
          },
          {
            name: "vehicles_nav",
            icon: "mdi-rv-truck",
            tag: "vehicles",
            to: "/vehicles",
            active: false,
          },
          {
            name: "rentals_nav",
            icon: "mdi-car-key",
            tag: "rentals",
            to: "/rentals",
            active: false,
          },
          {
            name: "facturation_nav",
            icon: "mdi-cash-100",
            active: false,
            tag: "billing",
            children: [
              {
                name: "facturation_add_nav",
                icon: "mdi-cash-100",
                tag: "billing",
                to: "/billing/add",
                active: false,
              },
              {
                name: "facturation_list_nav",
                icon: "mdi-format-list-bulleted-square",
                tag: "billing",
                to: "/billing",
                active: false,
              },
            ],
          },
          {
            name: "stock_nav",
            icon: "mdi-view-module",
            tag: "stocks",
            to: "/stocks",
            active: false,
          },
          {
            name: "reception_nav",
            icon: "mdi-account-tie",
            tag: "receptions",
            to: "/receiptions",
            active: false,
          },
          {
            name: "scheduler_nav",
            icon: "mdi-calendar",
            tag: "scheduler",
            to: "/scheduler",
            active: false,
          },
          {
            name: "tyre_hotel_nav",
            icon: "mdi-blur-radial",
            tag: "tyre_depot",
            to: "/depot",
            active: false,
          },
        ],
      },
      {
        name: "settings_nav",
        icon: "mdi-filmstrip",
        tag: "settings",
        active: false,
        children: [
          {
            name: "company_setting_nav",
            icon: "mdi-office-building-cog",
            tag: "company_settings",
            to: "/company-setting",
            active: false,
          },
          {
            name: "users_nav",
            icon: "mdi-account-multiple",
            tag: "users",
            to: "/users",
            active: false,
          },
          {
            name: "family_clients_nav",
            icon: "mdi-account-switch",
            tag: "family_clients",
            to: "/family-clients",
            active: false,
          },
          {
            name: "family_articles_nav",
            icon: "mdi-alpha-a-box",
            tag: "family_articles",
            to: "/family-articles",
            active: false,
          },
          {
            name: "brands_nav",
            icon: "mdi-alpha-b-box",
            to: "/brands",
            tag: "brands",
            active: false,
          },
          {
            name: "user_rights",
            icon: "mdi-account",
            tag: "user_rights",
            to: "/user-rights",
            active: false,
          },
          {
            name: "article_pricing_nav",
            icon: "mdi-cash-register",
            tag: "article_pricing",
            to: "/article-pricing",
            active: false,
          },
          {
            name: "pricing_nav",
            icon: "mdi-cash-multiple",
            tag: "pricing_rules",
            to: "/pricing-rules",
            active: false,
          },
          {
            name: "suppliers_nav",
            icon: "mdi-routes",
            to: "/suppliers",
            tag: "suppliers",
            active: false,
          },
        ],
      },
    ],
    profile_list: [
      {
        title: "profileDropDown.myProfile",
        data: null,
        icon: "mdi-account-circle",
        child: [],
        divider: true,
        active: false,
        action: "showProfile",
      },
      {
        title: "appLanguage.en",
        data: "en",
        icon: "",
        child: [],
        divider: false,
        active: false,
        action: "changeLanguage",
      },
      {
        title: "appLanguage.fr",
        data: "fr",
        icon: "",
        child: [],
        divider: false,
        active: false,
        action: "changeLanguage",
      },
      {
        title: "appLanguage.de",
        data: "de",
        icon: "",
        child: [],
        divider: false,
        active: false,
        action: "changeLanguage",
      },
      {
        title: "appLanguage.nl",
        data: "nl",
        icon: "",
        child: [],
        divider: true,
        active: false,
        action: "changeLanguage",
      },
      {
        title: "profileDropDown.logout",
        data: null,
        icon: "mdi-power-standby",
        child: [],
        divider: false,
        active: false,
        action: "logout",
      },
    ],
    dialog: false,
    right: null,
    drawer: null,
    confirmationDialog: false,
    confirmationDialogConfirmationText: null,
    confirmationDialogFalseText: null,
    confirmationDialogTrueText: null,
    confirmationDialogButtonClickedIs: null,
    confirmationDialogOperation: null,
  }),
  created() {
    this.$i18n.locale = this.findCompanyLocale();
    this.active_tree_items = [];
    this.profile_list.forEach((element) => {
      if (element.data === localStorage.getItem("languageId")) {
        element.active = true;
      }
    });
    this.checkActiveTreeKey(this.nav_items);
  },
  mounted() {
    this.nav_items = this.filterNavItems(this.nav_items);
  },
  methods: {
    filterNavItems(nav_items) {
      let newNavItems = [];
      nav_items.forEach((element) => {
        if (this.checkTag(element.tag)) {
          if (element.children) {
            element.children = this.filterNavItems(element.children);
          }
          newNavItems.push(element);
        }
      });
      return newNavItems;
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    logoutPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "navigation.logout_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("navigation.no");
      this.confirmationDialogTrueText = this.$t("navigation.yes");
      this.confirmationDialogOperation = "logout";
      this.confirmationDialog = true;
    },
    getCompanyName() {
      return localStorage.getItem("company_name");
    },
    getUserRole() {
      return localStorage.getItem("userRole");
    },
    selectActiveKey(event) {
      if (event.length > 0) {
        let navItem = this.getObject(this.nav_items, event[0]);
        this.$router.push(navItem.to).catch(() => {});
      }
    },
    getObject(theObject, valueSearch) {
      var result = null;
      if (theObject instanceof Array) {
        for (var i = 0; i < theObject.length; i++) {
          result = this.getObject(theObject[i], valueSearch);
          if (result) {
            break;
          }
        }
      } else {
        for (var prop in theObject) {
          if (prop == "name") {
            if (theObject[prop] == valueSearch) {
              return theObject;
            }
          }
          if (
            theObject[prop] instanceof Object ||
            theObject[prop] instanceof Array
          ) {
            result = this.getObject(theObject[prop], valueSearch);
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    },
    checkActiveTreeKey(nav_items) {
      let matched = false;
      nav_items.forEach((element) => {
        if (element.to && element.to == this.$route.path) {
          this.active_tree_items.push(element.name);
          matched = true;
        }
        if (element.children) {
          if (this.checkActiveTreeKey(element.children)) {
            this.active_tree_opened_items.push(element.name);
            matched = true;
          }
        }
      });
      return matched;
    },
    onListClick(action, data) {
      if (action === "logout") {
        this.logoutPopUp();
      } else if (action === "changeLanguage") {
        console.log("change locale", this.findCompanyLocale(data));
        this.$i18n.locale = this.findCompanyLocale(data);
        // Change Vue and user language
        this.$vuetify.lang.current = this.findCompanyLocale(data);
        localStorage.setItem("languageId", data);
        this.$store.state.languageId = data;

        this.profile_list.forEach((element) => {
          if (element.action === "changeLanguage") {
            element.active = false;
            if (element.data === localStorage.getItem("languageId")) {
              element.active = true;
            }
          }
        });
      }
    },
    logout() {
      const token = this.$store.state.AccessToken;
      axios({
        method: "get",
        url: API_BASE_URL + "/logout",
        headers: {
          Authorization: "Bearer " + token,
        },
      }).finally(() => {
        localStorage.clear();
        this.$store.state.AccessToken = "";
        this.$router.push("/login").catch(() => {});
      });
      this.dialog = false;
    },
  },
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "logout" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.logout();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>

<style scoped></style>
